@import url("https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,700");

.container{
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-top: 60px;
}
.inner-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 160px;
}
.text-info-links{
  margin-bottom: 150px;
}
.logo-img {
    height: 100%;
    width: 100px;
}
/* .vl {
    border-left: 4px solid rgb(37, 146, 204);
    height: 25px;
    margin-left: 5px;
    margin-right: 5px;
  } */

  .text-into {
    font-size: 30px;
    font-weight: bold;
  }

  .text-into-sec {
    font-size: 20px;
  }

  .buttons-intro{
    display: flex;
    flex-direction: column;
  }

  .button-flex{
    background-color: rgb(37, 146, 204);
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    padding: 7px;
    margin: 7px 0;
    transition: 0.3s;
    border: 2px solid rgba(0, 128, 0, 0);
    margin-left: 50px;
  }

  .button-flex-img{
    height: 100%;
    width: 30px;
    margin-top: 7px;
    margin-left: 10px;
  }

  .button-flex p{
    text-align: center !important;
    color: white;
    font-size: 14px;  
    margin-left: 150px;
  }

  .button-flex p a{
    text-decoration: none;
    color: white;
  }

  .social-media-flex{
    display: flex;
    flex-direction: row;
    align-self: center;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .social-img{
    margin: 20px 13px;
    height: 100%;
    width: 35px;
    transition: 0.2s;
  }

  .button-flex:hover {
    background-color: rgba(0, 128, 0, 0);
    border: 2px solid rgb(37, 146, 204);
    color: rgb(37, 146, 204);
  }

  .button-flex:hover > p a {
    color: rgb(37, 146, 204);
    font-weight: bold;
  }

  .button-flex:hover > p  {
    color: rgb(37, 146, 204);
    font-weight: bold;
  }

  .button-flex:hover > .button-flex-img  {
    color: rgb(37, 146, 204) !important;;
  }

  .button-flex:hover > .link-decoration p  {
    color: rgb(37, 146, 204) !important;;
  }

  .social-img:hover {
    transform: scale(1.075);
  }

  .link-decoration{
    text-decoration: none;
  }
  


/* Responsive 300 to 500px */
  @media screen and (min-width: 300px) and (max-width: 500px){

    .inner-container{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 0px;
      margin: 0 20px;
    }
    .text-info-links{
      margin-bottom: 30px;
    }
    .logo-img {
      height: 100%;
      width: 90px;
      display: block;
      margin-left: auto;
      margin-right: auto;
  }
  .button-flex{
    background-color: rgb(37, 146, 204);
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    padding: 7px;
    margin: 7px 0;
    transition: 0.3s;
    border: 2px solid rgba(0, 128, 0, 0);
    margin-left: 0px;
  }
  .button-flex p{
    text-align: right;
    color: white;
    font-size: 14px;  
    margin-left: 150px;
  }
  }