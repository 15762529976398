@import url("https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,700");

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}
.list > li > .menu-button{
  text-decoration: none;
  color: rgb(209, 30, 30);
}
@media screen and (min-width: 700px){
  nav {
    position: fixed;
    align-items: center;
    top: 0;
    margin: 0px;
    width: 100%;
    background: transparent;
    display: flex;
    flex-direction: row;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #d8f6ffb0;
}
.lang-footer{
	display: none;
   }
.social-media-flex-header{
  display: none;
  flex-direction: row;
  align-self: center;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
}
.list {
    margin: 0px 50px;
    list-style-type: none;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.logo-img-header {
    margin: 0px 10px;
    height: 100%;
    width: 130px;
}

.active{
  color: rgb(37, 146, 204) !important;
  font-weight: bold;
  border-bottom: 2px solid rgb(37, 146, 204);
}
.items {
  margin: 20px;
  font-size: 17px;
  color: #000;
  text-align: center;
  cursor: pointer;
}
.lang{
  text-decoration: none;
  color:rgb(37, 146, 204);
}
.pickup-button {
text-decoration: none;
color: #fff;
}

.request{
  background-color: rgb(37, 146, 204);
  color: #fff;
  padding: 10px;
  border-radius: 9px;
  margin-left: 330px;
}

.dis{
  display: none;
}

.menu-btn {
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: all .5s ease-in-out;
    display: none;
    position: absolute;
    right: 10px;
    top: 27px;
    padding: 5px;
    background-color: transparent;
    border: none;
  }

  .menu-btn__burger {
    width: 30px;
    height: 3px;
    background: rgb(5, 86, 129);
    border-radius: 5px;
    transition: all .2s ease-in-out;
  }

  .menu-btn__burger::before,
  .menu-btn__burger::after {
    content: '';
    position: absolute;
    width: 30px;
    height: 3px;
    background: rgb(5, 86, 129);
    border-radius: 5px;
    transition: all .3s ease-in-out;
  }

  .menu-btn__burger::before {
    transform: translateY(-9px);
  }

  .menu-btn__burger::after {
    transform: translateY(9px);
  }

  /* ANIMATION */
  .menu-btn.open .menu-btn__burger {
    transform: translateX(-50px);
    background: transparent;
    box-shadow: none;
  }

  .menu-btn.open .menu-btn__burger::before {
    transform: rotate(45deg) translate(35px, -35px);
  }
  
  .menu-btn.open .menu-btn__burger::after {
    transform: rotate(-45deg) translate(35px, 35px);
  }

/* 500px max width */
@media screen and (max-width: 500px){

    .list {
        flex-direction: column;
        height: auto;
        text-align: center;
    }

    .items:nth-child(1){
        border-top: 1px solid rgba(255, 255, 255, 0.555);
    }
    .lang{
      display: none;
    }
    .dis{
      display: flex !important;
    }
    .menu-btn {
        display: block;
    }
    .logo-img-header {
        margin: 0px 10px;
        margin-top: 6px;
        height: 100%;
        width: 120px;
    }
    .request{
      background-color: rgb(37, 146, 204);
      color: #fff;
      padding: 10px 30px;
      border-radius: 9px;
      margin-left: 22px;
    }
    .list{
      width: 100%;
      text-align: center;
      margin: 2px;
      padding: 4px 0;
      justify-content: center;
      background-color: rgb(255, 255, 255);
      border-bottom-right-radius: 50px;
      margin: 0px 0px;
      box-shadow: 1px 8px 18px 1px rgba(0,0,0,0.29);
      
    }
    .social-media-flex-header{
     background-color: #a7edff8c;
     border-bottom-right-radius: 50px;
     margin-right: 0px;
    padding: 0 20%;
    }
    
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 10;
      margin-top: 50px;
  }
}

/* 300px max width */
@media screen and (min-width: 300px) and (max-width: 375px){

  .social-media-flex-header{
   background-color: #a7edff8c;
   border-bottom-right-radius: 50px;
   margin-right: 0px;
  padding: 0 10%;
  }
}