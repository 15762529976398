.floating-container {
    position: fixed;
    width: 100px;
    height: 100px;
    bottom: 0;
    right: 0;
    margin: 35px 25px;
    z-index: 999999;
}
.floating-container .floating-button {
    position: absolute;
    width: 65px;
    height: 65px;
    background: #4caf50;
    bottom: 0;
    border-radius: 50%;
    left: 0;
    right: 0;
    margin: auto;
    color: #fff;
    line-height: 65px;
    text-align: center;
    font-size: 23px;
    z-index: 100;
    box-shadow: 0 10px 25px -5px rgb(44 240 103 / 60%);
    cursor: pointer;
    transition: all .3s;
}
*, .btn, :after, :before, a, button {
    transition: .2s ease-in-out;
}
.iconsi{
    margin-top: 11px;
    font-size:xx-large;
    line-height: 2;
}
