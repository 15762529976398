@import url("https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,700");


.img-bg{
    position: relative;
    z-index: 1; 
    background-image: url("../../Assets/images/bg/bg-img.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 650px;
    margin-top: -10px;
}
/* .highlighted{
  color: rgb(155, 220, 255);
} */
.hided{
  display: none;
}
.input-field-custom{
  display: flex;
  flex-direction: row;
  width: 700px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
  height: 50px;
  background-color: rgba(224, 224, 224, 0.589);
}
.input-custom{
  width: 700px;
  padding: 12px;
  border: none;
  box-sizing: border-box;
  margin-top: -12px;
  margin-bottom: -12px;
  margin-left: 10px;
  resize: vertical;
  height: 50px;
  background-color: rgba(224, 224, 224, 0);
}
.contain-numbs{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.code-number{
  margin-left: 10px;
}
.title-form-sec{
  margin-bottom: 20px;
}
.title-pick {
  max-width: 29ch;
  padding: 10px;
  font-weight: bold;
  line-height: 85px;
  margin-top: 150px !important;
  background-color: rgba(5, 86, 129, 0.548);
  color: rgb(255, 255, 255);
}
.text-pick {
  max-width: 39ch;
  padding: 10px;
  background-color: rgba(5, 86, 129, 0.548);
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 20px;
  margin-top: -42px;
}
.words{
    display: flex;
    flex-direction: row;
    margin: 0px 230px;
    font-size: 75px;
}
.cloth-img{
    height: 450px;
    width: 450px;
    margin-left: 10px;
    margin-top: 120px;
}

.explaining-img{
    height: 670px;
    width: 100%;
}
.explaining-block{
    display: flex;
    flex-direction: row;
    margin: 0px 220px;
    margin-top: -30px;
  }
  .explaining-title{
    font-size: 35px;
    font-weight: bold;
    color: rgb(5, 86, 129);
    text-align: center;
  }
  .form-Title{
    font-size: 35px;
    font-weight: bold;
    color: rgb(5, 86, 129);
    text-align: center;
  }
  .form-under-line{
    border-bottom: 3px solid rgb(5, 86, 129);
      width: 80px;
      display: block;
      margin-left: auto;
      margin-right: auto
  }
  .form-under-line-mission{
    border-bottom: 3px solid rgb(255, 255, 255);
      width: 80px;
      display: block;
      margin-left: auto;
      margin-right: auto
  }
  .explaining-text{
    margin-top: 163px;
    font-size: 1.3rem;
    color: rgb(61 113 166);
  }
  .explaining-bg{
    position: relative;
    z-index: 1; 
    background-image: url("../../Assets/images/bg/bgOne.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 2800px;
  }

  /* fields */
  .fields-bg{
    position: relative;
    z-index: 1; 
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 850px;
    margin-top: -25px;
  }

  .input-custom-one, select, textarea {
    width: 700px;
    padding: 12px;
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    height: 50px;
    background-color: rgba(224, 224, 224, 0.589);
  }
  .Send-Message-Block{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    border-radius: 13px;
    padding: 40px;
    margin: 120px 380px;
    box-shadow: 2px 4px 13px 2px rgba(0,0,0,0.34);
  }
textarea {
    height: 200px;
    font-size: 15px;
  }

  .Contacts-Button{
    width: 700px;
    height: 50px;
    border-radius: 7px;
    border: none;
    color: white;
    background-color: rgb(37, 146, 204);
    font-size: 15px;
    font-weight: bold;
  }
  .address-sec{
    margin-bottom: 180px;
    margin-right: 50px;
  }

  select {
    width: 700px;
    padding: 16px 20px;
    border: none;
    border-radius: 4px;
    background-color: #f1f1f1;
  }




  /* section-two */

.Features-Main{
    margin: 210px 150px;
    margin: 0 0px;
    padding-top: 10px;
    color: rgb(255 255 255);
    background-image: url("../../Assets/images/bg/bg-mission.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
 
}
.Features-Title{
    text-align: center;
    font-size: 55px;
    color: rgb(5, 86, 129);
    margin-top: 55px;
}
.Features-Title-Mission{
  text-align: center;
  font-size: 55px;
  color: rgb(255, 255, 255);
  margin-top: 55px;
}
.Feature-h1{
  color: rgb(255, 255, 255);
}
.under-line{
  border-bottom: 3px solid rgb(5, 86, 129);
    width: 80px;
    display: block;
    margin-left: auto;
    margin-right: auto
}
.Features-Text{
    display: block;
    text-align: center;
    max-width: 80ch;
    margin-left: auto;
    margin-right: auto;
}
.Feature-1{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.img1{
    width: '100%';
    height: 70px;
}
.Features-Text-box{
    display: block;
    text-align: center;
    max-width: 25ch;
    font-size: 17px;
}
.Features-Details{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.Features-Row-1{
    margin: 30px;
}





/*---media queries---*/
@media (max-width: 1440px) {
  .Send-Message-Block{
    margin: 120px 341px;
  }
}
  @media (max-width: 1024px) {

/* Features-Section */
  .under-line{
    border-bottom: 3px solid rgb(5, 86, 129);
    width: 80px;
    display: block;
    margin-left: auto;
    margin-right: auto
}
.Send-Message-Block{
  margin: 120px 131px;
}
  .Features-Main{
    margin: -43px 50px;
    margin: 0 0px;
    padding-top: 10px;
    color: rgb(255 255 255);
    background-image: url("../../Assets/images/bg/bg-mission.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
 
}
  .Features-Title{
      text-align: center;
      font-size: 55px;
  }
  .Features-Row-1{
      margin: 70px;
  }
  .Feature-h1{
    text-align: center;
  }
  .explaining-img {
    height: 450px;
    width: 100%;
}
.items {
  margin: 24px !important;
  margin-top: 25px;
  font-size: 15px !important;
  color: #000;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
}
.cloth-img {
  height: 350px;
  width: 350px;
  margin-left: 10px;
  margin-top: 120px;
}
.explaining-text {
  margin-top: 70px;
  font-size: 1.3rem;
  color: rgb(61 113 166);
}
.title-pick {
  max-width: 38ch !important;
  padding: 10px;
  color: rgb(255 255 255);
  font-weight: bold;
  line-height: 65px;
  margin-top: 130px !important;
  font-size: 55px !important;
}
}


@media (max-width: 768px) {

/* Features-Section */
.Features-Main {
  margin: 190px 0;
}
.Features-Title{
    text-align: center;
    font-size: 45px;
    margin-top: 30px;
}
.words {
  display: flex;
  flex-direction: row;
  margin: 0px 156px;
  font-size: 75px;
}

.under-line{
  border-bottom: 3px solid rgb(5, 86, 129);
    width: 80px;
    display: block;
    margin-left: auto;
    margin-right: auto
}
.Features-Text{
    display: block;
    text-align: center;
    max-width: 60ch;
    margin-left: auto;
    margin-right: auto
}
.Feature-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: -63px;
}
.img1{
    width: '100%';
    height: 70px;
}
.Features-Text-box{
    display: block;
    text-align: center;
    font-size: 17px;
}
.Features-Details{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 30px;
}
.Features-Row-1{
    margin: 100px;
}
  }

  

@media screen and (min-width: 300px) and (max-width: 500px){

.words{
    display: flex;
    flex-direction: column;
    margin: 0px 10px;
    margin-top: 40px;
    align-items: center;
}
.img1{
  background-color: rgb(5, 86, 129);
  border-radius: 10px;
  padding: 5px;
  }
  .Feature-h1 {
    color: rgb(5, 86, 129);
  }
.Features-Title-Mission{
  text-align: center;
  font-size: 55px;
  color: rgb(5, 86, 129);
  margin-top: 55px;
}
.form-under-line-mission{
  border-bottom: 3px solid rgb(5, 86, 129);
    width: 80px;
    display: block;
    margin-left: auto;
    margin-right: auto
}
.floating-container {
  position: fixed;
  width: 100px;
  height: 100px;
  bottom: 0;
  right: 0;
  margin: 35px 3px;
  z-index: 999999;
}
.form-Title{
  font-size: 35px;
  font-weight: bold;
  color: rgb(5, 86, 129);
  text-align: center;
}
.form-under-line{
  border-bottom: 3px solid rgb(5, 86, 129);
    width: 80px;
    display: block;
    margin-left: auto;
    margin-right: auto
}
.Feature-1{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: -61px 0;
  border-bottom: 3px solid rgb(5, 86, 129);
  width: 280px;
}
.Features-Main{
  margin: 30px 0px;
  background-image: none;
  color: rgb(5, 86, 129);
}
.title-pick{
    max-width: 9ch;
    padding: 10px;
    color: rgb(255, 255, 255);
    font-size: 60px;
    line-height: 75px;
    background-color: rgba(5, 86, 129, 0.548);
    align-self: center !important;
    margin-top: 50px !important;
}
.cloth-img {
  height: 250px;
  width: 250px;
  margin-top: -22px;
}
.img-bg{
    width: 100%;
    height: 650px;
    margin-top: 0px;
    overflow: hidden;
}
.arrow-img{
   display: none;
}

.text-pick {
  background-color: rgba(0, 121, 34, 0);
  color: rgb(255 255 255);
}

.explaining-img{
  display: none;
}

.explaining-block {
  display: flex;
  flex-direction: row;
  margin: 0px 50px;
  margin-top: -84px;
}

.explaining-bg {
  position: relative;
  z-index: 1;
  background-image: url(http://localhost:3000/static/media/bgOne.c551451….png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 4119px
}

.explaining-text {
  margin-top: 141px;
  font-size: 1.3rem;
}

.Features-Details{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
}

 /* fields */
 .fields-bg{
  position: relative;
  z-index: 1;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 850px;
  margin-top: -25px;
}
.input-custom{
  width: 300px;
  padding: 12px;
  border: none;
  box-sizing: border-box;
  margin-top: -7px;
  margin-bottom: -12px;
  margin-left: 10px;
  resize: vertical;
  height: 40px;
  font-size: 17px;
  background-color: rgba(224, 224, 224, 0);
}
.input-custom-one, .input-field-custom, select, textarea {
  width: 300px;
  padding: 12px;
  border: none;
  box-shadow: -1px 0px 13px 0px rgba(0,0,0,0.3);
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
  height: 50px;
  background-color: rgb(255, 255, 255);
  font-size: 17px;
}
.Send-Message-Block{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 121, 34, 0);
  border-radius: 8px;
  padding: 40px;
  margin: 7px 5px;
  background-color: rgba(224, 224, 224, 0);
  box-shadow: -1px 0px 13px 0px rgba(0, 0, 0, 0);
}
textarea {
  height: 200px;
  font-size: 15px;
}

.Contacts-Button{
  width: 300px;
  height: 50px;
  border-radius: 7px;
  border: none;
  color: white;
  font-size: 15px;
  font-weight: bold;
}
.address-sec{
  margin-bottom: 180px;
  margin-right: 50px;
}
}
@media (max-width: 375px) {
  .Contacts-Button{
    width: 342px;
    height: 50px;
    border-radius: 7px;
    border: none;
    color: white;
    background-color: rgb(37, 146, 204);
    font-size: 17px;
    font-weight: bold;
  }
  .input-custom-one, .input-field-custom, select, textarea {
    width: 342px;
    padding: 12px;
    border: none;
    box-shadow: -1px 0px 13px 0px rgba(0,0,0,0.3);
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    height: 50px;
    background-color: rgb(255, 255, 255);
    font-size: 17px;
  }
}